import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import useScrollPosition from '@react-hook/window-scroll'
import { useWindowHeight } from '@react-hook/window-size'
import IconButton from '@mui/material/IconButton'
import CircleIcon from '@mui/icons-material/Circle' 
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined' 
import useDocumentTitle from '@rehooks/document-title'

import MenuIcon from '@mui/icons-material/Menu'
import { useSwipeable } from 'react-swipeable'
// @ts-ignore
import KeyboardEventHandler from 'react-keyboard-event-handler'

type ImageProps = {
  src: string
}
const Image = ({src}: ImageProps) =>
  <div className="holder" style={{backgroundImage: `url(${src})`}}/>


type CircleProps = {
  active?: boolean
  onPress: () => void
}
const Circle = ({active, onPress}: CircleProps) =>
  <div className="circle" onClick={onPress}>
    {active ? <CircleIcon /> : <CircleOutlinedIcon />}
  </div>

const data = [{
  name: 'the swarm',
  short: 'eine stetig wachsende Arbeit aus hunderten kleiner Figurinen (ca. 10 cm hoch) aus Gips',
  long: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur facere consequuntur quibusdam nemo cum iure nihil ipsa tenetur voluptatum. Optio aliquid reprehenderit ullam unde harum illum dolorem velit dicta ut? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur facere consequuntur quibusdam nemo cum iure nihil ipsa tenetur voluptatum. Optio aliquid reprehenderit ullam unde harum illum dolorem velit dicta ut? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur facere consequuntur quibusdam nemo cum iure nihil ipsa tenetur voluptatum. Optio aliquid reprehenderit ullam unde harum illum dolorem velit dicta ut? ',
  images: [
    '/swarm.0.jpg',
    '/swarm.1.jpg',
    '/swarm.2.jpg'
  ]
}, {
  name: 'impressions',
  short: 'Glas geblasen, Holz, 120, 70, 70 cm',
  long: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur facere consequuntur quibusdam nemo cum iure nihil ipsa tenetur voluptatum. Optio aliquid reprehenderit ullam unde harum illum dolorem velit dicta ut? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur facere consequuntur quibusdam nemo cum iure nihil ipsa tenetur voluptatum. Optio aliquid reprehenderit ullam unde harum illum dolorem velit dicta ut? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur facere consequuntur quibusdam nemo cum iure nihil ipsa tenetur voluptatum. Optio aliquid reprehenderit ullam unde harum illum dolorem velit dicta ut? ',
  images: [
    '/impressions.0.jpg',
    '/impressions.1.jpg',
    '/impressions.2.jpg'
  ],
}, {
  name: 'chain',
  short: '',
  long: '',
  images: [
    '/chain.0.jpg',
  ],
}, {
  name: 'letters',
  short: 'eine fiktive Schrift die sich zur Struktur verdichtet, wieder aufbricht, so wolkenhafte Grafiken entstehen lässt.',
  long: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur facere consequuntur quibusdam nemo cum iure nihil ipsa tenetur voluptatum. Optio aliquid reprehenderit ullam unde harum illum dolorem velit dicta ut? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur facere consequuntur quibusdam nemo cum iure nihil ipsa tenetur voluptatum. Optio aliquid reprehenderit ullam unde harum illum dolorem velit dicta ut? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur facere consequuntur quibusdam nemo cum iure nihil ipsa tenetur voluptatum. Optio aliquid reprehenderit ullam unde harum illum dolorem velit dicta ut? ',
  images: [
    '/letters.0.jpg',
    '/letters.1.jpg',
    '/letters.2.jpg',
    '/letters.3.jpg',
    '/letters.4.jpg',
    '/letters.5.jpg',
    '/letters.6.jpg',
  ],
}, {
  name: 'portraits',
  short: 'Fotogramme, die die innere Struktur der < impressions > sichtbar machen.',
  long: '',
  images: [
    '/portraits.0.jpg',
    '/portraits.1.jpg',
  ]
}, {
  name: 'alice',
  short: '<alice> ist eine Reihe an Arbeiten die sich mit den fraktalen Strukturen in den Fotogrammen beschäftigt und immer detaillierter in den Mikrokosmos vordringt.',
  long: '',
  images: [
    '/alice.0.jpg',
    '/alice.1.jpg',
  ]
}, {
  name: 'lightsculptures',
  short: 'Die Lichtplastiken geben dem Betrachter die Möglichkeit mit der generierten Grafik zu interagieren.',
  long: '',
  images: [
    '/light.0.jpg',
    '/light.1.gif',
  ]
}, {
  name: 'dear lighthouse',
  short: 'Ein Cape aus etwa 5000 Mottenflügelnachbildungen, Autolack, Chiffon, Kleiderbügel, Licht. Größe S',
  long: '',
  images: [
    '/lighthouse.0.jpg',
    '/lighthouse.1.jpg',
    '/lighthouse.2.jpg',
    '/lighthouse.3.jpg',
  ]
}]

const App = () => {
  const [activeTab, setActiveTab] = useState<number>(0)
      , [activeImage, setActiveImage] = useState<number>(0)
      , [drawer, setDrawer] = useState(false)
      , height = useWindowHeight()
      , y = useScrollPosition(15)

  document.title = data[activeTab].name

  const handleSwipe = (direction: string) => {
    switch (direction) {
      case 'Left': activeTab < data.length-1 && setActiveTab(activeTab + 1); break;
      case 'Right': activeTab > 0 && setActiveTab(activeTab - 1); break;
      case 'Up': window.scrollTo({top: (Math.round(y/height)+1) * height, behavior: 'smooth'}); break;
      case 'Down': window.scrollTo({top: (Math.round(y/height)-1) * height, behavior: 'smooth'}); break;
    }
  }

  const handlers = useSwipeable({
    onSwiped: ({dir}) => handleSwipe(dir),
    trackMouse: true
  })

  useEffect(() => {
    if (drawer)
      setDrawer(false)  
  }, [y])

  return (
    <div className="App">
      <KeyboardEventHandler
          handleKeys={['Left', 'Up', 'Down', 'Right']}
          onKeyEvent={(key: any, e: Event) => {
            setTimeout(() =>
              handleSwipe(
                key === 'Left' ? 'Right' :
                key === 'Right' ? 'Left' :
                key === 'Up' ? 'Down' :
                'Up'
              ), 10)
          }} />
      <div className="header">
        {data.map((name: any, idx: number) =>
          <Circle
            key={`nav${idx}`}
            active={idx === activeTab}
            onPress={() => setActiveTab(idx)}
          />
        )}
      </div>
      <div className="sidebar">
        {data[activeTab].images.map((name: any, idx: number) =>
          <Circle
            key={`image${idx}`}
            active={Math.round(y / height) === idx}
            onPress={() => {
              window.scrollTo({
                top: height * idx,
                behavior: 'smooth'
              })
            }}
          />
        )}
      </div>
      <div className="content" {...handlers}>
        {data[activeTab].images.map((src: any, idx: number) =>
          <Image key={`image${idx}`} src={src} />
        )}
      </div>
      <div className="footer">
        <div className="left">
          {data[activeTab].name}
        </div>
        <div className="right">
          <div className="top">
            {data[activeTab].short}
          </div>
          <div className="bottom">
            {data[activeTab].long}
          </div>          
        </div>
      </div>

      <div className="info" style={{height: drawer ? height * 0.3 : 0}}>

      </div>
      <div className="button">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setDrawer(!drawer)}
          className="button"
          edge="start"
        >
          <MenuIcon fontSize="large"/>
        </IconButton>
      </div>

    </div>
  );
}

export default App;
